import React, { useEffect, useRef, useState } from "react";
import { Alert, Button, Form, Table } from "tabler-react";
import ReactModal from "react-modal";
import * as Showdown from "showdown";
import ReactMde from "react-mde";
import "react-mde/lib/styles/css/react-mde-all.css";
import { useQuery } from "@apollo/react-hooks";
import { GET_AUTOMATION_INPUT_OUTPUT_TYPES } from "../../graphql/queries";
import { MultiSelect } from "react-multi-select-component";
//import Select from "react-select";

ReactModal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "30%",
    height: "50%",
  },
};

const TableModal = ({
  handleModal,
  showModal,
  onSave,
  onUpdate,
  editField,
  errorMessage,
  automationTypeList,
  categoryList,
  automationData,
  automationSuggestions,
  selected,
  setSelected,
}: any): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [text, setText] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [selectedType, setSelectedType] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [name, setName] = useState("");
  const [url, setUrl] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [longDescription, setLongDescription] = useState("");
  const [longDescriptionDetails, setLongDescriptionDetails] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [iconUrl, setIconUrl] = useState("");
  // const [creditPrice, setCreditPrice] = useState<number | string>("")
  // const [premiumCreditPrice, setPremiumCreditPrice] = useState<number | string>("")
  const [active, setActive] = useState(false);
  const [isNew, setIsNew] = useState(true);
  const [has_source, setHasSource] = useState(false);
  const [source_txt, setSourceTxt] = useState(false);
  const [source_csv, setSourceCsv] = useState(false);
  const [multi, setMulti] = useState(false);
  const bodyRef = useRef(null);
  const [tab, setTab] = useState<"write" | "preview">("write");
  const [inputTypesList, setInputTypesList] = useState<any>([]);
  const [checkedInputTypesList, setCheckedInputTypesList] = useState<any>([]);
  const [outputTypeKey, setOutputTypeKey] = useState("");
  const [outputTypeValue, setOutputTypeValue] = useState("text");
  const [outputTypeValuesList, setOutputTypesValuesList] = useState<any>(null);
  const [automationOrder, setAutomationOrder] = useState("not-first");

  const { data } = useQuery(GET_AUTOMATION_INPUT_OUTPUT_TYPES, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (automationData) {
      automationData.order && setAutomationOrder(automationData.order);
      automationData.inputs && setCheckedInputTypesList(automationData.inputs.split(", "));
      automationData.outputs && setOutputTypesValuesList(JSON.parse(automationData.outputs));
      typeof automationData.multi === "boolean" && setMulti(automationData.multi);
    }
  }, [automationData]);

  useEffect(() => {
    if (
      data &&
      data.HexomaticAdmin &&
      data.HexomaticAdmin.getAutomationInputTypes &&
      data.HexomaticAdmin.getAutomationInputTypes.types
    ) {
      const list = data.HexomaticAdmin.getAutomationInputTypes.types.map((item: any) => item.name);
      setInputTypesList(list);
      list[0] && setOutputTypeValue(list[0]);
    }
  }, [data]);

  useEffect(() => {
    if (automationTypeList && automationTypeList.length) {
      if (automationTypeList[0].id === null) {
        setSelectedType(+automationTypeList[1].id);
        return;
      }
      setSelectedType(+automationTypeList[0].id);
    }
  }, [automationTypeList]);

  useEffect(() => {
    if (categoryList && categoryList.length) {
      if (categoryList[0].id === null) {
        setSelectedCategory(+categoryList[1].id);
      }
      setSelectedCategory(+categoryList[0].id);
    }
  }, [categoryList]);

  const handleOk = async () => {
    if (
      !selectedType ||
      !selectedCategory ||
      !name ||
      !url ||
      !metaTitle ||
      !metaDescription ||
      !longDescription ||
      !shortDescription ||
      !iconUrl
    ) {
      setErrMsg("please fill all fileds");
      return;
    }
    setLoading(true);
    if (editField) {
      const formData = {
        id: editField.id,
        category_id: editField.category.id === selectedCategory ? null : selectedCategory,
        type_id: editField.type.id === selectedType ? null : selectedType,
        name: editField.name === name ? null : name,
        url: editField.url === url ? null : url,
        meta_title: editField.meta_title === metaTitle ? null : metaTitle,
        meta_description: editField.meta_description === metaDescription ? null : metaDescription,
        short_description: editField.short_description === shortDescription ? null : shortDescription,
        long_description: editField.long_description === longDescription ? null : longDescription,
        long_description_details:
          editField.long_description_details === longDescriptionDetails ? null : longDescriptionDetails,
        icon_url: editField.icon_url === iconUrl ? null : iconUrl,
        // credit_price: editField.credit_price === creditPrice ? null : creditPrice,
        // premium_credit_price: editField.premium_credit_price === premiumCreditPrice ? null : premiumCreditPrice,
        is_new: isNew,
        active,
        has_source,
        order: automationOrder,
        multi: multi,
        inputs: checkedInputTypesList.join(", "),
        outputs: JSON.stringify(outputTypeValuesList),
        source_csv,
        source_txt,
      };
      await onUpdate({ ...formData });
      setLoading(false);
      return;
    }
    const formData = {
      category_id: selectedCategory,
      type_id: selectedType,
      name,
      url,
      meta_title: metaTitle,
      meta_description: metaDescription,
      short_description: shortDescription,
      long_description: longDescription,
      long_description_details: longDescriptionDetails,
      icon_url: iconUrl,
      // credit_price: creditPrice,
      // premium_credit_price: premiumCreditPrice,
      is_new: isNew,
      active,
      has_source,
      order: automationOrder,
      multi: multi,
      inputs: inputTypesList.join(", "),
      outputs: JSON.stringify(outputTypeValuesList),
      source_txt,
      source_csv,
    };
    const completed = await onSave({ ...formData });
    setLoading(false);
    if (completed) handleCancel();
  };

  const handleCancel = () => {
    setEmail("");
    setSelectedType(null);
    setSelectedCategory(null);
    setName("");
    setUrl("");
    setMetaTitle("");
    setMetaDescription("");
    setLongDescription("");
    setLongDescriptionDetails("");
    setShortDescription("");
    setIconUrl("");
    // setCreditPrice(null)
    // setPremiumCreditPrice(null)
    handleModal();
  };

  useEffect(() => {
    if (editField) {
      setSelectedType(editField.type.id);
      setSelectedCategory(editField.category.id);
      setName(editField.name);
      setUrl(editField.url);
      setMetaTitle(editField.meta_title);
      setMetaDescription(editField.meta_description);
      setLongDescription(editField.long_description);
      setLongDescriptionDetails(editField.long_description_details);
      setShortDescription(editField.short_description);
      setIconUrl(editField.icon_url);
      // setCreditPrice(editField.credit_price)
      // setPremiumCreditPrice(editField.premium_credit_price)
      setActive(!!editField.active);
      setHasSource(!!editField.has_source);
      setIsNew(!!editField.is_new);
    }
  }, [editField]);

  useEffect(() => {
    if (window.innerWidth < 640) {
      customStyles.content.width = "90%";
    }
  }, []);

  // const onHandleCreditPrice = (e: any) => {
  //   errMsg && setErrMsg('')
  //   if (e.target.value === '') {
  //     setCreditPrice(null)
  //     return
  //   }
  //   if (isNaN(+e.target.value)) {
  //     setErrMsg("please type number")
  //     return
  //   }
  //   setCreditPrice(+(e.target as HTMLInputElement).value)
  // }

  // const onHandlePremiumCreditPrice = (e: any) => {
  //   errMsg && setErrMsg('')
  //   if (e.target.value === '') {
  //     setPremiumCreditPrice(null)
  //     return
  //   }
  //   if (isNaN(+e.target.value)) {
  //     setErrMsg("please type number")
  //     return;
  //   }
  //   setPremiumCreditPrice(+(e.target as HTMLInputElement).value)
  // }

  const handleTabChange = (tab: "write" | "preview") => setTab(tab);

  const converter = new Showdown.Converter({
    tables: true,
    simplifiedAutoLink: true,
    strikethrough: true,
    tasklists: true,
  });

  const handleAddingOutputValue = () => {
    const list = { ...outputTypeValuesList };
    if (outputTypeKey && outputTypeValue) {
      list[outputTypeKey] = outputTypeValue;
      setOutputTypesValuesList(list);
      setOutputTypeKey("");
      setOutputTypeValue("text");
      return;
    } else {
      setErrMsg("Please fill all the fields");
    }
  };

  const handleDeletingOutputValue = (obj: any) => {
    const list = { ...outputTypeValuesList };
    delete list[obj[0]];
    const listLength = Object.keys(list) && Object.keys(list).length;
    setOutputTypesValuesList(listLength > 0 ? list : null);
  };

  return (
    <div style={{ height: 0 }} ref={bodyRef}>
      <ReactModal
        isOpen={showModal}
        contentLabel="Create Automation"
        style={customStyles}
        onRequestClose={handleCancel}
      >
        <h4>{editField ? "Edit Automation" : "Create Automation"}</h4>
        {errorMessage || errMsg ? <Alert type="danger">{errorMessage || errMsg}</Alert> : null}
        <Form onSubmit={(e: React.FormEvent) => e.preventDefault()}>
          {automationTypeList && (
            <Form.Select
              onChange={(e: React.FocusEvent) => {
                setSelectedType(+(e.target as HTMLInputElement).value);
              }}
              label="Type"
              value={selectedType || ""}
            >
              {automationTypeList.map((pack) => (
                <option key={pack.name} value={pack.id}>
                  {pack.name}
                </option>
              ))}
            </Form.Select>
          )}
          {categoryList && (
            <Form.Select
              onChange={(e: React.FocusEvent) => setSelectedCategory(+(e.target as HTMLInputElement).value)}
              label="Category"
              value={selectedCategory || ""}
            >
              {categoryList.map((pack) => (
                <option key={pack.name} value={pack.id}>
                  {pack.name}
                </option>
              ))}
            </Form.Select>
          )}
          <Form.Input
            required
            type="text"
            placeholder="name"
            label="Name"
            value={name}
            onChange={(e: React.FocusEvent) => setName((e.target as HTMLInputElement).value)}
          />
          <Form.Input
            required
            type="text"
            placeholder="url"
            label="URL"
            value={url}
            onChange={(e: React.FocusEvent) => setUrl((e.target as HTMLInputElement).value)}
          />
          <Form.Input
            required
            type="text"
            placeholder="meta title"
            label="Meta title"
            value={metaTitle}
            onChange={(e: React.FocusEvent) => setMetaTitle((e.target as HTMLInputElement).value)}
          />
          <Form.Input
            type="text"
            placeholder="meta description"
            label="Meta description"
            value={metaDescription}
            onChange={(e: React.FocusEvent) => setMetaDescription((e.target as HTMLInputElement).value)}
          />
          <Form.Textarea
            onChange={(e: React.FocusEvent) => setShortDescription((e.target as HTMLInputElement).value)}
            placeholder="short description"
            type="text"
            label="Short description"
            value={shortDescription}
          ></Form.Textarea>

          <label className="form-label">Long Description</label>
          <ReactMde
            onChange={(value: string) => setLongDescription(value)}
            onTabChange={handleTabChange}
            value={longDescription}
            generateMarkdownPreview={(markdown) => Promise.resolve(converter.makeHtml(markdown))}
            selectedTab={tab}
            className="mb-2"
          />

          <label className="form-label">Long Description details</label>
          <ReactMde
            onChange={(value: string) => setLongDescriptionDetails(value)}
            onTabChange={handleTabChange}
            value={longDescriptionDetails}
            generateMarkdownPreview={(markdown) => Promise.resolve(converter.makeHtml(markdown))}
            selectedTab={tab}
            className="mb-2"
          />

          <Form.Input
            required
            type="text"
            placeholder="icon url"
            label="Icon URL"
            value={iconUrl}
            onChange={(e: React.FocusEvent) => setIconUrl((e.target as HTMLInputElement).value)}
          />
          {/* <Form.Input
            required
            type="text"
            placeholder='credit price'
            label="credit price"
            value={creditPrice}
            onChange={onHandleCreditPrice}
          />
          <Form.Input
            required
            type="text"
            placeholder='premium credit price'
            label="premium credit price"
            value={premiumCreditPrice}
            onChange={onHandlePremiumCreditPrice}
          /> */}
          <div style={{ display: "flex" }}>
            <span style={{ marginRight: "5px" }}>{"has_source"}</span>
            <Form.Checkbox label=" " checked={has_source} onChange={() => setHasSource(!has_source)} />
          </div>
          <div style={{ display: "flex" }}>
            <span style={{ marginRight: "5px" }}>{"active"}</span>
            <Form.Checkbox
              label=" "
              checked={active}
              onChange={(e) => {
                setActive(!active);
              }}
            />
          </div>
          <div style={{ display: "flex" }}>
            <span style={{ marginRight: "5px" }}>{"is new"}</span>
            <Form.Checkbox
              label=" "
              checked={isNew}
              onChange={(e) => {
                setIsNew(!isNew);
              }}
            />
          </div>
          <div style={{ display: "flex" }}>
            <span style={{ marginRight: "5px" }}>{"multi"}</span>
            <Form.Checkbox
              label=" "
              checked={multi}
              onChange={(e) => {
                setMulti(!multi);
              }}
            />
          </div>
          <div style={{ marginTop: "10px", marginBottom: "10px" }}>
            <label className="form-label">Suggested automations (max 3 automations)</label>
            <MultiSelect
              options={automationSuggestions}
              value={selected}
              onChange={setSelected}
              labelledBy="Select"
              hasSelectAll={false}
              className={`${selected.length > 2 ? "multi-select-disabled" : ""}`}
            />
          </div>
          <div>
            <Form.Select
              onChange={(e: React.FocusEvent) => {
                setAutomationOrder((e.target as HTMLInputElement).value);
              }}
              label="Automation order"
              value={automationOrder}
            >
              {["not-first", "only-first", "any"].map((pack) => (
                <option key={pack} value={pack}>
                  {pack}
                </option>
              ))}
            </Form.Select>
          </div>

          <p className="settings-title">
            <span>Output types</span> (columns returned by automation)
          </p>
          <div>
            {outputTypeValuesList && (
              <Table className="table card-table table-modal">
                <Table.Header>
                  <Table.Row>
                    <Table.ColHeader className="lg">name</Table.ColHeader>
                    <Table.ColHeader className="md">type</Table.ColHeader>
                    <Table.ColHeader className="sm"></Table.ColHeader>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {Object.entries(outputTypeValuesList).map((item: any) => (
                    <Table.Row key={item[0]}>
                      <Table.Col className="lg">
                        <div className="text-truncate max-w-300">{item[0]}</div>
                      </Table.Col>
                      <Table.Col className="md">
                        <div className="text-truncate max-w-150">{item[1]}</div>
                      </Table.Col>
                      <Table.Col className="sm">
                        <div onClick={() => handleDeletingOutputValue(item)} className="cursor-pointer">
                          <svg
                            width="8"
                            height="8"
                            viewBox="0 0 8 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M4.88111 4.00011L7.8722 1.00889C7.95447 0.926545 7.99987 0.816691 8 0.699553C8 0.58235 7.9546 0.472366 7.8722 0.390155L7.61008 0.128106C7.52767 0.0455695 7.41782 0.000366211 7.30055 0.000366211C7.18348 0.000366211 7.07363 0.0455695 6.99122 0.128106L4.00013 3.11913L1.00891 0.128106C0.926634 0.0455695 0.816715 0.000366211 0.699512 0.000366211C0.582439 0.000366211 0.47252 0.0455695 0.390244 0.128106L0.128 0.390155C-0.0426667 0.560821 -0.0426667 0.838415 0.128 1.00889L3.11915 4.00011L0.128 6.9912C0.0456585 7.07367 0.000325203 7.18352 0.000325203 7.30066C0.000325203 7.4178 0.0456585 7.52765 0.128 7.61006L0.390179 7.87211C0.472455 7.95458 0.582439 7.99985 0.699447 7.99985C0.81665 7.99985 0.926569 7.95458 1.00885 7.87211L4.00006 4.88102L6.99115 7.87211C7.07356 7.95458 7.18341 7.99985 7.30049 7.99985H7.30062C7.41776 7.99985 7.52761 7.95458 7.61002 7.87211L7.87213 7.61006C7.95441 7.52772 7.9998 7.4178 7.9998 7.30066C7.9998 7.18352 7.95441 7.07367 7.87213 6.99126L4.88111 4.00011Z"
                              fill="#8C979D"
                            ></path>
                          </svg>
                        </div>
                      </Table.Col>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            )}
          </div>

          <div className="add-output-key-container">
            <div className="lg">
              <Form.Input
                required
                type="text"
                placeholder="name"
                label="name"
                value={outputTypeKey}
                onChange={(e: React.FocusEvent) => setOutputTypeKey((e.target as HTMLInputElement).value)}
              />
            </div>
            <div className="md">
              <Form.Select
                onChange={(e: React.FocusEvent) => {
                  setOutputTypeValue((e.target as HTMLInputElement).value);
                }}
                label="output type"
                value={outputTypeValue}
              >
                {inputTypesList.map((pack) => (
                  <option key={pack} value={pack}>
                    {pack}
                  </option>
                ))}
              </Form.Select>
            </div>
            <div className="btn-container sm">
              <Button onClick={handleAddingOutputValue} color="primary" type="button">
                Add
              </Button>
            </div>
          </div>
          <p className="settings-title">
            <span>Input types</span> (types that automation can accept as a source)
          </p>
          <div>
            {inputTypesList.map((row: any) => (
              <Form.Checkbox
                className=""
                label={row}
                checked={checkedInputTypesList.includes(row)}
                onChange={() => {
                  let list = [...checkedInputTypesList];
                  if (list.includes(row)) {
                    list = list.filter((sub: any) => sub !== row);
                  } else {
                    list.push(row);
                  }
                  setCheckedInputTypesList(list);
                }}
              />
            ))}
          </div>

          <Button.List style={{ marginTop: "5%" }}>
            <Button onClick={handleOk} color="primary" type="submit" loading={loading}>
              Save
            </Button>
            <Button onClick={handleCancel} color="secondary" type="button">
              Cancel
            </Button>
          </Button.List>
        </Form>
        <span ref={bodyRef}></span>
        {errorMessage || errMsg ? <Alert type="danger">{errorMessage || errMsg}</Alert> : null}
      </ReactModal>
    </div>
  );
};

export default TableModal;
