import Cookies from "js-cookie";

export const setUserToken = (token: string) => Cookies.set('user-token', token);
export const getUserToken = (): string | undefined => Cookies.get('user-token');
export const setUserName = (username: string) => Cookies.set("username", username);
export const getUserName = (): string | undefined => Cookies.get("username");
const removeUserToken = (): void => Cookies.remove('user-token');
const removeUserName = (): void => Cookies.remove('username');

const removeSession = (): void => Cookies.remove('__session', {domain: '.hexometer.com'});
const removeRefToken = (): void => Cookies.remove('ref_token', {domain: '.hexometer.com'});
const removeDeviceKey = (): void => Cookies.remove('device_key', {domain: '.hexometer.com'});

export const getSession = (): string | undefined => Cookies.get('__session');
export const removeUserInfo = (): void => {
  removeSession();
  removeRefToken();
  removeDeviceKey();
}
export const clearCookies = (): void => {
  removeUserToken();
  removeUserName();
};

export const isProduction = () => process.env.REACT_APP_HTTP_HOST === 'https://api.hexometer.com/v2/admin/ql';

export const getCountArr = (length:number) => {
  let newArr = [7]
  const totalTimes = Math.floor(length / 30) +1;
  for(let i = 1; i <= totalTimes; i++) {
    if(i * 30 <= length) {
      newArr.push(i * 30)
    }else {
      newArr.push(length)
    }
  }
  return newArr
}

export function isValidEmail(mail: string): boolean {
  return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(mail);
}

export const alertCustomStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '30%',
    height: 'auto'
  }
};

export const integrationOptions = [
  {name: 'zapier', value: 'zapier'},
  {name: 'discord', value: 'discord'},
  {name: 'email', value: 'email'},
  {name: 'trello', value: 'trello'},
  {name: 'telegram', value: 'telegram'},
  {name: 'slack', value: 'slack'},
  {name: 'sms', value: 'sms'}
]

export const getMB = (size: number) => {
  const kb = 1000;
  const mb = kb * 1000;
  if (size > mb) {
    return `${(size / mb).toFixed(2)} MB`;
  }

  return `${(size / kb).toFixed(2)} KB`;
};
